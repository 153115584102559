import React from 'react';
import s from './LoaderSlide.module.scss';

function LoaderSlide() {
  return (
    <div className={s.box}>
      <div className={s.container}>
        {/* <span className={s.circle}></span>
        <span className={s.circle}></span>
        <span className={s.circle}></span>
        <span className={s.circle}></span> */}
      </div>
    </div>
  );
}

export default LoaderSlide;
